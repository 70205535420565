import React from 'react'
import Image from 'gatsby-image'

export default ({ className, outerClassName = ``, WrapperComp = `div`, WrapperCompProps = {}, ...props }) => {

  return (
    <WrapperComp className={`absolute left-0 top-0 z-0 ${outerClassName}`} {...WrapperCompProps}>
      <Image
        objectFit="cover"
        objectPosition="center"
        className={className}
        draggable={false}
        {...props}
        imgStyle={{
          objectFit: "cover",
          objectPosition: "center",
        }}
      />
    </WrapperComp>
  )
}
