import React from 'react'
import ImportUpload from './import-upload'

const Logo = ({modulePath, ...props}) => (
  <ImportUpload modulePath={modulePath}>
    {({ default: Component }) => {
      return <Component {...props} />
    }}
  </ImportUpload>
)

export default Logo
