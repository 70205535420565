import React, { useMemo, useLayoutEffect, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'
import { useMediaQueryContext } from '../context/media'
import { Parallax } from 'react-scroll-parallax'
import SkewBackground from 'components/SkewBackground'
import SquaresSVG, { SquaresBackgroundSVG } from 'components/SquaresBackground'
import ContactForm from 'components/contact-form'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import SEO from '../components/seo'
import BackgroundImage from 'components/background-image'
import ImportUpload from 'components/import-upload'
import IdSwiper from 'react-id-swiper/lib/ReactIdSwiper.custom'
import { Swiper, Pagination, Autoplay } from 'swiper/dist/js/swiper.esm.js'
import 'react-id-swiper/lib/styles/css/swiper.css'
// import { useController as useParallaxController } from 'react-scroll-parallax'
import { theme } from '../twconfig'
import viewportMotion from '../components/viewport-motion'
import Logo from '../components/UploadedLogo'
import TransitionPage from '../transition-page'

const ProjectTemplate = ({ data: { project, iphoneFrame: iphoneSharpFluid, nextProject, firstProject } }) => {
  const breakpoints = useMediaQueryContext()

  const {
    route,
    title,
    description,
    projectURL,
    projectYear,
    mainImage,
    screenshots,
    role,
    technologies,
    meta,
  } = project

  const sectionParallaxY = useMemo(() => {
    return breakpoints.lg ? [`150px`, `0px`] : [`50px`, `0px`]
  }, [breakpoints.lg])

  const iphoneParallaxY = useMemo(() => {
    return breakpoints.lg ? [`150px`, `0px`] : [`45px`, `-30px`]
  }, [breakpoints.lg])

  const gotoProject = useMemo( () => nextProject || firstProject, [firstProject, nextProject] )

  return (
    <TransitionPage className="pt-12 lg:pt-20">
      <SEO pathname={route} {...meta} />

      <div className="container md:pt-8 lg:relative">
        <div className="md:mx-12 lg:mx-0 lg:w-1/2 lg:py-24">
          <header className="text-center mb-8 lg:text-left">
            <SquaresBackgroundSVG
              rows={3}
              width={136}
              minWidthPerSquare={33}
              padding={0}
              className="pt-16 lg:pt-12 xl:pt-16"
              svgClassName="right-0 top-0"
            >
              <viewportMotion.h1
                className="text-4xl md:text-5xl uppercase leading-none mb-2"
                inViewportOptions={{ threshold: 0.1 }}
              >
                {title}
              </viewportMotion.h1>
            </SquaresBackgroundSVG>

            {projectURL && (
              <viewportMotion.a
                target="_blank"
                className="text-white font-semibold border-b-2 border-brand"
                rel="noopener noreferrer"
                href={projectURL}
              >
                {projectURL.replace(/(^\w+:|^)\/\//, "")}
              </viewportMotion.a>
            )}
          </header>

          {mainImage.sharp && (
            <div className="project-image mb-8 lg:mb-0 right-0 top-0 lg:translate-center-y lg:px-6 lg:w-1/2">
              {mainImage.sharp ? (
                <Image
                  draggable={false}
                  loading="eager"
                  fluid={mainImage.sharp.fluid}
                  className="rounded"
                />
              ) : null}
            </div>
          )}

          <viewportMotion.div className="flex flex-row flex-no-wrap -mx-5 mb-12">
            <div className="px-5 w-2/3">
              <h3>Client</h3>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <div className="px-5 w-1/3">
              <h3>Year</h3>
              <div dangerouslySetInnerHTML={{ __html: projectYear }} />
            </div>
          </viewportMotion.div>
        </div>
      </div>

      {/* Big Screenshots */}
      {screenshots && (
        <Parallax
          tagOuter="div"
          y={sectionParallaxY}
          styleInner={{ transform: `translateY(${sectionParallaxY[0]})` }}
        >
          <SkewBackground
            className="flex flex-col py-12 justify-center py-16 mb-10 md:mt-12"
            bgColor="#222"
          >
            <div className="container">
              <div className="-my-20">
                {screenshots.map(({ image, description }, idx) => {
                  const even = idx % 2 !== 0
                  const screenShotClasses = even
                    ? "-right-1/3 lg:right-auto"
                    : "-left-1/3 lg:left-auto"

                  return (
                    <div
                      key={idx}
                      className={`flex flex-col flex-no-wrap mb-8 last:mb-0 md:mb-16 lg:flex-row lg:items-center ${
                        even ? `lg:flex-row-reverse` : ``
                      }`}
                    >
                      <viewportMotion.div
                        className="project-screenshot lg:w-1/2"
                        inViewportOptions={{ threshold: 0.75 }}
                      >
                        <Parallax
                          y={sectionParallaxY}
                          styleInner={{
                            transform: `translateY(${sectionParallaxY[0]})`,
                          }}
                        >
                          <Image
                            draggable={false}
                            fluid={image.sharp.fluid}
                            className={`${screenShotClasses} rounded`}
                          />
                        </Parallax>
                      </viewportMotion.div>

                      <div
                        className={`project-screenshot-text hidden lg:block lg:w-1/2 lg:flex-auto`}
                      >
                        <viewportMotion.div
                          inViewportOptions={{ threshold: 0.75 }}
                          className={`lg:mt-32 xxl:mt-0 ml-20 mr-20`}
                          dangerouslySetInnerHTML={{
                            __html: description.remark.html,
                          }}
                        />
                      </div>
                    </div>
                  )
                })}

                <SquaresSVG
                  fillColor="black"
                  width="110"
                  className="absolute bottom-0 left-0"
                />
              </div>
            </div>
          </SkewBackground>
        </Parallax>
      )}

      {/* Our role & mobile slider */}
      <div className="container pb-12 relative z-10 pt-24 md:pt-32 lg:pt-32 lg:pb-20 lg:min-h-screen-1/3">
        {role && (
          <div className="lg:flex lg:flex-row lg:flex-no-wrap md:mx-12 lg:mx-0">
            {role.content.remark && (
              <viewportMotion.div
                inViewportOptions={{ threshold: 0.75 }}
                className="lg:ml-0 lg:mr-20"
                dangerouslySetInnerHTML={{ __html: role.content.remark.html }}
              />
            )}

            {role.screenshots && (
              <Parallax
                y={iphoneParallaxY}
                styleOuter={{ zIndex: 50 }}
                styleInner={{ transform: `translateY(${iphoneParallaxY[0]})` }}
              >
                <viewportMotion.div
                  variants={{ hidden: { x: 100, opacity: 0 } }}
                  transition={{ mass: 1, stiffness: 30 }}
                  inViewportOptions={{ threshold: 0.5 }}
                  className="relative h-24 pt-8 lg:-mt-32"
                >
                  <IphoneSlider
                    className="absolute z-10 right-0 lg:relative lg:overflow-hidden"
                    frameImageFluid={iphoneSharpFluid}
                    items={role.screenshots}
                  />
                </viewportMotion.div>
              </Parallax>
            )}
          </div>
        )}
      </div>

      {/* Technologies */}
      {technologies && (
        <Parallax
          tagOuter="div"
          y={sectionParallaxY}
          styleInner={{ transform: `translateY(${sectionParallaxY[0]})` }}
        >
          <SkewBackground className="py-16 -mb-20 lg:-mb-24 xxl:-mb-32" bgColor={theme.colors.gray[`600`]}>
            <div className="container pb-12 md:pt-12 lg:pb-24 xxl:pb-32">
              <div className="pt-16 md:mx-12 lg:mx-0 lg:w-3/5">
                <h2 className="mb-8 md:mb-12">Techs in use</h2>

                <TechList className="lg:flex lg:flex-row lg:flex-wrap lg:-mx-5 lg:items-start">
                  {technologies.map(({ id, logo, title, route }) => (
                    <TechListItem
                      key={id}
                      className="relative lg:w-1/3 lg:px-5"
                    >
                      <div className="flex flex-row flex-no-wrap items-center">
                        <div className="flex-shrink-0 bg-white p-2 w-12 h-12 mr-4 rounded-full flex justify-center items-center">
                          <Logo
                            className="block max-w-full h-auto"
                            modulePath={logo.relativePath}
                          />
                        </div>
                        <div className="flex-grow">
                          <Link className="stretched-link" to={route}>
                            {title}
                          </Link>
                        </div>
                      </div>
                    </TechListItem>
                  ))}
                </TechList>
              </div>
            </div>
          </SkewBackground>
        </Parallax>
      )}

      {/* Contact form */}
      <Parallax
        tagOuter="aside"
        y={sectionParallaxY}
        styleInner={{ transform: `translateY(${sectionParallaxY[0]})` }}
        className=""
      >
        <SkewBackground
          bgColor={theme.colors.white}
          className="-mb-20 py-20 pt-28 pb-20 xxl:-mb-32"
        >
          <h2 className="section-title text-gray-500 mb-10">
            Say Hello World
            <small className="block text-base text-gray-400 text-brand normal-case font-light tracking-normal">
              and let's get the job done
            </small>
          </h2>

          <div className="container">
            <ContactForm
              className="md:max-w-sm mx-auto"
              name="contact"
              data-netlify="true"
              netlify-honeypot="fullname"
            />
          </div>
        </SkewBackground>
      </Parallax>

      {gotoProject && <NextProject {...gotoProject} />}
    </TransitionPage>
  )
}

const NextProjectBase = ({
  title,
  route,
  gotoNext: {
    bgImage,
    screenshot,
  },
  className,
  ...props
}) => {

  return (
    <div className={`relative z-0 ${className}`}>
      {bgImage && bgImage.sharp && (
        <BackgroundImage fluid={bgImage.sharp.fluid} outerClassName="h-full w-full" className="h-full" />
      )}

      <div className="container absolute inset-0 flex flex-row justify-center items-center">
        <div className="inner flex flex-row flex-no-wrap items-center justify-between mx-auto">
          <div className="text-white">
            <div className="font-semibold">Next project</div>
            <h3 className="text-lg"><Link to={route} className="stretched-link hover:text-inherit focus:text-inherit">{title}</Link></h3>
          </div>
          
          {screenshot && screenshot.sharp && (
            <div className="-mr-6" style={{ width: `50%` }}>
              <Image draggable={false} fluid={screenshot.sharp.fluid} className="rounded" style={{ pointerEvents: `none` }} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const NextProject = styled(NextProjectBase)`
  ${tw`-mt-12`}
  height: 22rem;
  
  .container {
    ${tw`pt-48`}

    .inner {
      width: 30rem;
    }
  }
  /* ${tw`lg:pt-48 xl:pt-56`} */
  
  /* @media (min-width: ${theme.screens.md}) {
  } */

  @media (min-width: ${theme.screens.md}) {
    /* ${tw`pt-48`} */  
    height: 24rem;
    
    .container {
      ${tw`pt-48`}

      .inner {
        width: 32rem;
      }
    }
  }

  @media (min-width: ${theme.screens.lg}) {
    /* ${tw`pt-48`} */  
    /* height: 24rem; */
    
    .container {
      /* ${tw`pt-48`} */

      .inner {
        width: 38rem;
      }
    }
  }
  
  @media (min-width: ${theme.screens.xl}) {
    height: 26rem;
    
    .container {
      ${tw`pt-48`}
      
      .inner {
        width: 38rem;
        /* ${tw`pt-32`} */
      }
    } 
  }

  @media (min-width: ${theme.screens.xxl}) {
    height: 30rem;
    
    .container {
      ${tw`pt-56`}
      
      .inner {
        width: 38rem;
        /* ${tw`pt-32`} */
      }
    } 
  }
`

const swiperConfig = {
  Swiper,
  modules: [Pagination, Autoplay],
  slidesPerView: 1,
  spaceBetween: 0,
}

const IphoneSliderBase = ({ items, frameImageFluid, ...props }) => {
  const config = useMemo(() => ({
    ...swiperConfig,
    autoplay: {
      delay: 3000,
    }
  }), [])
  
  return (
    <div {...props}>
      <IdSwiper {...config}>
        {items &&
          items.map(({ id, sharp }) =>
            sharp ? (
              <IphoneSlide key={id}>
                <Image draggable={false} fluid={sharp.fluid} />
              </IphoneSlide>
            ) : null
          )}
      </IdSwiper>

      {frameImageFluid && frameImageFluid.sharp && (
        <Image draggable={false} fluid={frameImageFluid.sharp.fluid} style={{ position: `absolute` }} />
      )}
    </div>
  )
}

const IphoneSlider = styled(IphoneSliderBase)`
  width: 42vw;

  @media (min-width: ${theme.screens.md}) {
    width: 33vw;
  }

  @media (min-width: ${theme.screens.lg}) {
    width: 260px;
  }

  .gatsby-image-wrapper {
    width: 100%;
    z-index: 5;
    top: 0;
    pointer-events: none;
  }
  
  .swiper-container {
    background-color: ${theme.colors.black};
    margin-top: 5.72%;
    margin-left: 6.4%;
    margin-right: 6.4%;
    margin-bottom: 7%;
  }
`

const IphoneSlide = styled.div``

const TechListItem = styled.li``
const TechList = styled.ul`
  ${TechListItem} {
    ${tw`lg:my-3`}
    
    + ${TechListItem} {
      ${tw`mt-6 lg:mt-3`}
    }
  }
`

export default ProjectTemplate

export const adjacentProjectFragment = graphql`
  fragment AdjacentProject on Project {
    title
    route
    gotoNext {
      screenshot {
        sharp: childImageSharp {
          fluid(maxWidth: 660, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      bgImage {
        sharp: childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`

export const projectQuery = graphql`
  query ProjectPage($id: String!, $next: String, $first: String) {
    project(id: {eq: $id}) {
      ...CMS_Meta

      route
      title
      description
      projectURL
      projectYear(formatString: "YYYY")
      screenshots {
        image {
          id
          sharp: childImageSharp {
            fluid(maxWidth: 687, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
            
            # fixedLg: fixed(width: 687, height: 432, quality: 90) {
            #   ...GatsbyImageSharpFixed_withWebp
            # }
          }
        }
        
        description {
          remark: childMarkdownRemark {
            html
          }
        }
      }

      role {
        content {
          remark: childMarkdownRemark {
            html
          }
        }
        screenshots {
          id
          sharp: childImageSharp {
            fluid(maxWidth: 414, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }

      technologies {
        id
        logo {
          relativePath
        }
        route
        title
      }

      mainImage {
        sharp: childImageSharp {
          fluid(maxWidth: 688, maxHeight: 432, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }

    nextProject: project(id: {eq: $next}) {
      ...AdjacentProject
    }

    firstProject: project(id: {eq: $first}) {
      ...AdjacentProject
    }

    iphoneFrame: file(relativePath: {eq: "iphonebody.png"}) {
      sharp: childImageSharp {
        fluid(maxWidth: 474, quality: 90, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
